import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faChevronDown, faChevronUp, faMedal } from '@fortawesome/free-solid-svg-icons';

// Import player images
import bedardImage from './bedard.png';
import matthewsImage from './matthews.png';
import ovechkinImage from './ovechkin.png';

// Create axios instance with baseURL
const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001'
});

function App() {
  const [players, setPlayers] = useState([
    { id: '8471214', name: 'Alexander Ovechkin', image: ovechkinImage, goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/capitals/player/alex-ovechkin-8471214' },
    { id: '8479318', name: 'Auston Matthews', image: matthewsImage, goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/mapleleafs/player/auston-matthews-8479318' },
    { id: '8484144', name: 'Connor Bedard', image: bedardImage, goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/fi/player/connor-bedard-8484144' }
  ]);

  const [bubblingUnderPlayers, setBubblingUnderPlayers] = useState([
    { id: '8480027', name: 'Jason Robertson', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/jason-robertson-8480027' },
    { id: '8477934', name: 'Connor McDavid', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/connor-mcdavid-8477934' },
    { id: '8477956', name: 'David Pastrnak', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/david-pastrnak-8477956' },
    { id: '8474564', name: 'Steven Stamkos', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/steven-stamkos-8474564' },
    { id: '8477493', name: 'Leon Draisaitl', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/leon-draisaitl-8477493' }
  ]);

  const [sortConfig, setSortConfig] = useState({ key: 'gamesNeeded', direction: 'ascending' });
  const [isBubblingUnderVisible, setIsBubblingUnderVisible] = useState(false);
  const [maxGoals, setMaxGoals] = useState(0);
  const [nextGoal, setNextGoal] = useState(896);
  const [error, setError] = useState(null);

  const targetGoals = 895;

  const updateMaxGoals = (playerList) => {
    const newMaxGoals = Math.max(...playerList.map(p => p.goals));
    setMaxGoals(newMaxGoals);
    if (newMaxGoals >= targetGoals) {
      setNextGoal(newMaxGoals + 1);
    }
  };

  useEffect(() => {
    const fetchPlayerData = async (player) => {
      try {
        const response = await api.get(`/api/player/${player.id}`);
        return {
          ...player,
          goals: response.data.goals,
          gamesPlayed: response.data.gamesPlayed
        };
      } catch (error) {
        console.error('Error fetching player data:', error);
        setError('Failed to fetch player data. Please try again later.');
        return player;
      }
    };

    Promise.all(players.map(fetchPlayerData)).then(updatedPlayers => {
      setPlayers(updatedPlayers);
      updateMaxGoals(updatedPlayers);
    });
    Promise.all(bubblingUnderPlayers.map(fetchPlayerData)).then(updatedBubblingUnderPlayers => {
      setBubblingUnderPlayers(updatedBubblingUnderPlayers);
      updateMaxGoals([...updatedBubblingUnderPlayers, ...players]);
    });
  }, []);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const processPlayers = (playerList) => {
    return playerList.map(player => ({
      ...player,
      goalsPerGame: player.gamesPlayed > 0 ? player.goals / player.gamesPlayed : 0,
      goalsToGo: Math.max(0, targetGoals - player.goals),
      gamesNeeded: player.gamesPlayed > 0 ? Math.max(0, Math.ceil((targetGoals - player.goals) / (player.goals / player.gamesPlayed))) : Infinity
    }));
  };

  const sortedPlayers = useMemo(() => {
    let sortablePlayers = processPlayers(players);

    sortablePlayers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sortablePlayers;
  }, [players, sortConfig]);

  const sortedBubblingUnderPlayers = useMemo(() => {
    let sortablePlayers = processPlayers(bubblingUnderPlayers);

    sortablePlayers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sortablePlayers;
  }, [bubblingUnderPlayers, sortConfig]);

  const calculateMaxMinValues = (players) => {
    return {
      maxGoals: Math.max(...players.map(p => p.goals)),
      maxGamesPlayed: Math.max(...players.map(p => p.gamesPlayed)),
      maxGoalsPerGame: Math.max(...players.map(p => p.goalsPerGame)),
      minGoalsToGo: Math.min(...players.map(p => p.goalsToGo)),
      minGamesNeeded: Math.min(...players.map(p => p.gamesNeeded))
    };
  };

  const mainPlayerMaxMinValues = useMemo(() => calculateMaxMinValues(sortedPlayers), [sortedPlayers]);
  const bubblingUnderMaxMinValues = useMemo(() => calculateMaxMinValues(sortedBubblingUnderPlayers), [sortedBubblingUnderPlayers]);

  const SortArrows = ({ column }) => (
    <span className="ml-1">
      <span className={sortConfig.key === column && sortConfig.direction === 'ascending' ? 'text-blue-300' : 'text-gray-400'}>▲</span>
      <span className={sortConfig.key === column && sortConfig.direction === 'descending' ? 'text-blue-300' : 'text-gray-400'}>▼</span>
    </span>
  );

  const PlayerTable = ({ players, showImage = true, maxMinValues }) => {
    return (
      <div className="overflow-x-auto">
        <table className={`w-full divide-y divide-gray-200 ${maxGoals >= targetGoals ? 'opacity-50' : ''}`}>
          <thead className="bg-blue-600 text-white">
            <tr>
              <th scope="col" className="px-3 py-3 lg:px-2 lg:py-2 text-left text-base lg:text-sm font-medium uppercase tracking-wider">
                <button onClick={() => sortData('name')} className="text-left font-medium uppercase tracking-wider hover:text-blue-200 flex items-center">
                  Player <SortArrows column="name" />
                </button>
              </th>
              <th scope="col" className="px-3 py-3 lg:px-2 lg:py-2 text-center text-base lg:text-sm font-medium uppercase tracking-wider">
                <button onClick={() => sortData('goals')} className="font-medium uppercase tracking-wider hover:text-blue-200 flex items-center justify-center">
                  Goals <SortArrows column="goals" />
                </button>
              </th>
              <th scope="col" className="px-3 py-3 lg:px-2 lg:py-2 text-center text-base lg:text-sm font-medium uppercase tracking-wider">
                <button onClick={() => sortData('gamesPlayed')} className="font-medium uppercase tracking-wider hover:text-blue-200 flex items-center justify-center">
                  Games <SortArrows column="gamesPlayed" />
                </button>
              </th>
              <th scope="col" className="px-3 py-3 lg:px-2 lg:py-2 text-center text-base lg:text-sm font-medium uppercase tracking-wider">
                <button onClick={() => sortData('goalsPerGame')} className="font-medium uppercase tracking-wider hover:text-blue-200 flex items-center justify-center">
                  G/Game <SortArrows column="goalsPerGame" />
                </button>
              </th>
              <th scope="col" className="px-3 py-3 lg:px-2 lg:py-2 text-center text-base lg:text-sm font-medium uppercase tracking-wider">
                <button onClick={() => sortData('goalsToGo')} className="font-medium uppercase tracking-wider hover:text-blue-200 flex items-center justify-center">
                  Goals left <SortArrows column="goalsToGo" />
                </button>
              </th>
              <th scope="col" className="px-3 py-3 lg:px-2 lg:py-2 text-center text-base lg:text-sm font-medium uppercase tracking-wider">
                <button onClick={() => sortData('gamesNeeded')} className="font-medium uppercase tracking-wider hover:text-blue-200 flex items-center justify-center">
                  Games Left <SortArrows column="gamesNeeded" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {players.map((player) => (
              <tr key={player.id} className="hover:bg-blue-50 transition-colors duration-200">
                <td className="px-3 py-4 lg:px-2 lg:py-3 whitespace-nowrap">
                  <div className="flex flex-col sm:flex-row items-center">
                    {showImage && player.image && (
                      <img src={player.image} alt={player.name} className="w-20 h-20 lg:w-12 lg:h-12 object-cover rounded-full mb-2 sm:mb-0 sm:mr-4 border-2 border-blue-500 shadow-md" />
                    )}
                    <div>
                      <p className="text-xl lg:text-base font-semibold text-gray-900">{player.name}</p>
                      <a href={player.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 text-base lg:text-sm font-medium">
                        NHL Profile
                      </a>
                      {player.goals >= targetGoals && (
                        <div className="flex items-center mt-1">
                          <FontAwesomeIcon icon={faMedal} className="text-yellow-500 mr-1" />
                          <span className="text-base lg:text-sm font-bold bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-transparent bg-clip-text">
                            Mission Accomplished
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-3 py-4 lg:px-2 lg:py-3 whitespace-nowrap text-center">
                  <span className={`inline-block px-3 py-2 lg:px-2 lg:py-1 text-base lg:text-sm font-semibold rounded-full ${player.goals === maxMinValues.maxGoals ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'}`}>
                    {player.goals}
                  </span>
                </td>
                <td className="px-3 py-4 lg:px-2 lg:py-3 whitespace-nowrap text-center">
                  <span className={`inline-block px-3 py-2 lg:px-2 lg:py-1 text-base lg:text-sm font-semibold rounded-full ${player.gamesPlayed === maxMinValues.maxGamesPlayed ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'}`}>
                    {player.gamesPlayed}
                  </span>
                </td>
                <td className="px-3 py-4 lg:px-2 lg:py-3 whitespace-nowrap text-center">
                  <span className={`inline-block px-3 py-2 lg:px-2 lg:py-1 text-base lg:text-sm font-semibold rounded-full ${player.goalsPerGame.toFixed(2) == maxMinValues.maxGoalsPerGame.toFixed(2) ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'}`}>
                    {player.goalsPerGame.toFixed(2)}
                  </span>
                </td>
                <td className="px-3 py-4 lg:px-2 lg:py-3 whitespace-nowrap text-center">
                  <span className={`inline-block px-3 py-2 lg:px-2 lg:py-1 text-base lg:text-sm font-semibold rounded-full ${player.goalsToGo === maxMinValues.minGoalsToGo ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'}`}>
                    {player.goalsToGo}
                  </span>
                </td>
                <td className="px-3 py-4 lg:px-2 lg:py-3 whitespace-nowrap text-center">
                  <span className={`inline-block px-3 py-2 lg:px-2 lg:py-1 text-base lg:text-sm font-semibold rounded-full ${player.gamesNeeded === maxMinValues.minGamesNeeded ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'}`}>
                    {player.gamesNeeded}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 via-blue-300 to-white py-6 lg:py-4 px-4 lg:px-2">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl lg:text-3xl font-extrabold text-center mb-8 lg:mb-6">
          <span className="relative inline-block">
            <span className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 transform skew-x-12 rounded-lg"></span>
            <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100 
                             py-3 px-6 inline-block
                             transform hover:scale-105 transition-transform duration-200 ease-in-out
                             font-sans tracking-tight text-shadow-lg">
              Road to 895!
            </span>
          </span>
          {maxGoals >= targetGoals && (
            <>
              <br />
              <a 
                href={`https://www.godaddy.com/en-ie/domainsearch/find?domainToCheck=roadto${nextGoal}.com`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="mt-6 inline-block bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-3 px-8 lg:py-2 lg:px-6 rounded-full
                           transform hover:scale-105 transition-all duration-200 ease-in-out
                           shadow-lg hover:shadow-xl font-sans tracking-wide text-xl lg:text-lg"
              >
                What's next? Roadto{nextGoal}.com???
              </a>
            </>
          )}
        </h1>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 lg:px-3 lg:py-2 rounded relative mb-6 lg:mb-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="overflow-hidden bg-white rounded-xl shadow-2xl mb-8 lg:mb-6">
          <div className="overflow-x-auto">
            <PlayerTable 
              players={sortedPlayers} 
              showImage={true}
              maxMinValues={mainPlayerMaxMinValues}
            />
          </div>
        </div>
        <div className="mb-8 lg:mb-6">
          <button
            onClick={() => setIsBubblingUnderVisible(!isBubblingUnderVisible)}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 lg:py-3 px-6 lg:px-4 rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 flex items-center justify-center text-xl lg:text-lg"
          >
            <span className="mr-2">...Bubbling Under...</span>
            <FontAwesomeIcon icon={isBubblingUnderVisible ? faChevronUp : faChevronDown} />
          </button>
          {isBubblingUnderVisible && (
            <div className="mt-6 lg:mt-4 overflow-hidden bg-white rounded-xl shadow-2xl">
              <div className="overflow-x-auto">
                <PlayerTable
                  players={sortedBubblingUnderPlayers}
                  showImage={false}
                  maxMinValues={bubblingUnderMaxMinValues}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-center mb-6 lg:mb-4">
          <a href="https://www.buymeacoffee.com/visakytomay" target="_blank" rel="noopener noreferrer" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 lg:py-2 px-6 lg:px-4 rounded-lg shadow-lg hover:shadow-xl flex items-center transition-all duration-200 text-xl lg:text-lg">
            <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" className="w-6 h-6 lg:w-5 lg:h-5 mr-3 lg:mr-2" />
            <span>Buy me a coffee!<strong><FontAwesomeIcon icon={faSmile} className="ml-2 text-white" /></strong></span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
